<template>
  <div class="content-wrapper">
    <PageHeader screenName="Ver Pessoa Física" :linkItems="linkItems" />

    <main class="card">
      <validation-observer ref="itemRules">
        <b-form>
          <b-row class="pt-1 px-2">
            <b-col lg="4" md="6" sm="7">
              <b-form-group
                label="CPF"
                label-for="cpf-input"
                label-class="font_size_label_pessoa_fisica"
              >
                <validation-provider #default="{ errors }" name="cpf">
                  <b-form-input
                    id="cpf-input"
                    v-model="filter.cpf"
                    placeholder="000.000.000-00"
                    v-mask="'###.###.###-##'"
                    maxLength="14"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4" md="6" sm="7">
              <b-form-group
                label="Nome"
                label-for="nome-input"
                label-class="font_size_label_pessoa_fisica"
              >
                <b-form-input
                  id="nome-input"
                  placeholder="Nome"
                  v-model="filter.nome"
                />
              </b-form-group>
            </b-col>

            <b-col lg="4" md="6" sm="7">
              <b-form-group
                label="Data de nascimento"
                label-for="data-de-nascimento-input"
                label-class="font_size_label_pessoa_fisica"
              >
                <b-form-input
                  id="data-de-nascimento-input"
                  v-model="filter.dataDeNascimento"
                  placeholder="00/00/0000"
                  v-mask="'##/##/####'"
                  maxLength="10"
                />
              </b-form-group>
            </b-col>

            <b-col lg="4" md="6" sm="7">
              <b-button @click="filtrarPessoaFisica()" variant="primary-button">
                <feather-icon icon="SearchIcon" class="mr-50" />
                <span class="align-middle">Pesquisar</span>
              </b-button>

              <b-button
                type="reset"
                class="ml-2 cor_botao"
                @click="limparPesquisa()"
                variant="outline-primary"
              >
                <feather-icon icon="XIcon" class="mr-50" />
                <span class="align-middle">Limpar</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>

      <b-row class="pt-1 px-2">
        <b-col class="py-1 d-flex align-items-center" sm="12" lg="12">
          <b-col class="p-0">
            <div
              v-if="!this.filtering"
              class="d-flex justify-center-center align-items-center"
            >
              <span class="mr-50">Mostrar</span>
              <v-select
                id="orders"
                v-model="paginationData.defaultSize"
                :options="table.optionsSize"
                :clearable="false"
                @input="updateQtdView($event)"
              >
                <span slot="no-options">Nenhuma opção selecionável.</span>
              </v-select>
            </div>
          </b-col>
          <b-col class="text-right">
            <span>
              <b-button
                class="mr-0"
                @click="downloadSpreadsheet"
                variant="outline-primary"
                :disabled="table.items == 0"
              >
                <feather-icon icon="DownloadIcon" />
              </b-button>
            </span>
          </b-col>
        </b-col>

        <b-table
          id="listFailedJobTable"
          responsive
          sticky-header="100%"
          :no-local-sorting="true"
          :fields="getFieldsTable"
          :items="table.items"
          show-empty
          @sort-changed="ordemColumn"
          :no-sort-reset="true"
        >
          <template #empty>
            <span v-if="filtering">Processando.</span>
            <span v-else>Nenhum registro encontrado.</span>
          </template>

          <template #cell(cpf)="row">
            {{ row.item.cpf | VMask("###.###.###-##") }}
          </template>

          <template #cell(data_nascimento)="row">
            {{ formatDate(row.item.data_nascimento) }}
          </template>

          <template #cell(nome_mae)="row">
            {{ row.item.nome_mae ? row.item.nome_mae : "-" }}
          </template>

          <template #cell(descricao_nacionalidade)="row">
            {{
              row.item.descricao_nacionalidade
                ? row.item.descricao_nacionalidade
                : "-"
            }}
          </template>

          <template #cell(actions)="row">
            <span @click="editarPessoaFisica(row.item)">
              <button-icon color="#2772C0" size="18" feather-icon="EditIcon" />
            </span>
          </template>
        </b-table>
        <div class="pb-2 px-1 w-100" v-if="table.items.length > 0">
          <b-col sm="12" class="mt-2">
            <CustomPagination
              :paginacao="paginationData"
              @page-cliked="updateCurrentPage"
            />
          </b-col>
        </div>
      </b-row>
    </main>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BModal,
} from "bootstrap-vue";
import PageHeader from "@/views/components/custom/page-header/PageHeader.vue";
import CustomPagination from "@/views/components/custom/pagination/CustomPagination.vue";
import ButtonIcon from "@/views/components/custom/Buttons/ButtonIcon/ButtonIcon";
import moment from "moment";
import vSelect from "vue-select";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  title: "Ver Pessoa Física",

  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BModal,
    PageHeader,
    CustomPagination,
    ButtonIcon,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      required,
      filtering: false,
      linkItems: [
        {
          name: "Suporte",
          routeName: "",
        },
        {
          name: "Ver Pessoa Física",
          active: true,
        },
      ],
      table: {
        fields: [
          {
            key: "id_pessoa_fisica",
            sortable: true,
            label: "#",
          },
          { key: "cpf", sortable: true, label: "CPF" },
          { key: "nome", sortable: true, label: "Nome" },
          { key: "data_nascimento", sortable: true, label: "data nascimento" },
          { key: "genero", sortable: true, label: "Sexo" },
          { key: "nome_mae", sortable: false, label: "Nome da mãe" },
          {
            key: "descricao_nacionalidade",
            sortable: true,
            label: "Nacionalidade",
          },
          {
            key: "actions",
            label: "ações",
            sortable: false,
            class: "mw-150 text-center col-1",
            visible: true,
          },
        ],
        items: [],
        optionsSize: [10, 25, 50, 100],
        columnOrder: "nome",
        order: "asc",
      },
      paginationData: {
        currentPage: 1,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
        defaultSize: 10,
      },
      modal: {
        title: "",
        description: "",
      },
      filter: {
        cpf: "",
        nome: "",
        dataDeNascimento: "",
      },
    };
  },

  mounted() {
    this.buscarPessoaFisica();
  },

  methods: {
    buscarPessoaFisica() {
      this.table.items = [];
      this.$http
        .get(this.$api.suportePessoaFisica(), {
          params: this.setParams(),
        })
        .then((response) => {
          if (response.status == 200) {
            this.table.items = response.data.data;
            this.startPagination(response.data);
          }
          this.filtering = false;
        });
    },

    editarPessoaFisica(pessoa) {
      this.$router
        .push({
          name: "pessoa-fisica-edit",
          params: {
            idPessoaFisica: pessoa.id_pessoa_fisica,
            cpf: pessoa.cpf,
          },
        })
        .catch(() => {
          this.$router.push({
            name: "pessoa-fisica-list",
          });
        });
    },

    downloadSpreadsheet() {
      const applyCPFMask = (cpf) => {
        return cpf
          .replace(/\D/g, "")
          .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      };

      const content = this.table.items.map((item) => ({
        "id pessoa fisica": item.id_pessoa_fisica,
        cpf: applyCPFMask(item.cpf),
        "nome completo": item.nome,
        "data nascimento": moment(String(item.data_nascimento)).format(
          "DD/MM/YYYY"
        ),
        gênero: item.genero,
        "id nacionalidade": item.id_nacionalidade,
        "descrição nacionalidade": item.descricao_nacionalidade,
        "nome mãe": item.nome_mae,
      }));

      this.$helpers.downloadArchiveXlsx(content, "planilha_pessoa.xlsx");
    },

    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },

    startPagination(data) {
      this.paginationData.currentPage = data.current_page;
      this.paginationData.totalLines = data.total;
      this.paginationData.fromLine = data.from;
      this.paginationData.toLine = data.to;
    },

    ordemColumn(sort) {
      this.table.columnOrder = sort.sortBy;
      this.table.order = sort.sortDesc ? "desc" : "asc";
      this.filtering = true;
    },

    updateCurrentPage(page) {
      this.paginationData.currentPage = page;
      this.filtering = true;
    },

    updateQtdView(event) {
      if (!event) {
        this.paginationData.defaultSize = 10;
      }

      this.paginationData.currentPage = 1;
      this.filtering = true;
    },

    setParams() {
      return {
        colunaOrdenar: this.table.columnOrder,
        ordem: this.table.order,
        tamanho: this.paginationData.defaultSize,
        perPage: this.paginationData.defaultSize,
        page: this.paginationData.currentPage,
        cpf: this.filter.cpf,
        nome: this.filter.nome,
        data_nascimento: this.filter.dataDeNascimento,
      };
    },

    limparPesquisa() {
      this.paginationData.currentPage = 1;
      this.filter = {
        cpf: "",
        nome: "",
        dataDeNascimento: "",
      };
      this.filtering = true;
    },

    filtrarPessoaFisica() {
      this.$refs.itemRules.validate().then((success) => {
        if (success) {
          this.paginationData.currentPage = 1;
          this.filtering = true;
        }
      });
    },
  },

  computed: {
    getFieldsTable() {
      return this.table.fields;
    },
  },
  watch: {
    filtering: {
      immediate: true,
      handler() {
        if (this.filtering) {
          this.buscarPessoaFisica();
        }
      },
    },
  },
};
</script>
